@import url('https://fonts.googleapis.com/css?family=Poppins');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141823;
}

.background-main-dark {
  background-color: #141823;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cookie-consent-quote-image {
  margin-right: 15px;
  float: left;
}
.cookie-consent-quote-text {
  /*font-style: italic;*/
  position: relative;
}
.cookie-consent-quote-text::after {
  content: "“";
  font-size: 1.2em;
  position: relative;
  top: 12px;
  color: #eee;
}

.cookie-consent-quote-text::before {
  content: "”";
  font-size: 1.4em;
  position: relative;
  left: -2px;
  color: #eee;
}

.mobile-menu-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  z-index: 10;
}

.mobile-menu-open a::before {
  content: "› "
}

.mobile-menu-open.show {
  opacity: 1;
  visibility: visible;
  background: #282c34;
  z-index: 9;
}

.mobile-menu-close-icon {
  z-index: 20;
}
/*@keyframes dayToNight {*/
/*  0% {*/
/*    filter: brightness(1); !* Original brightness *!*/
/*  }*/
/*  50% {*/
/*    filter: brightness(0.5); !* Darker mode *!*/
/*  }*/
/*  100% {*/
/*    filter: brightness(1); !* Back to original *!*/
/*  }*/
/*}*/

/*.welcome-image {*/
/*  animation: dayToNight 10s infinite;*/
/*}*/

/*@keyframes lightBulb {*/
/*  0% {*/
/*    opacity: 0;*/
/*  }*/
/*  50% {*/
/*    opacity: 0.8;*/
/*  }*/
/*  100% {*/
/*    opacity: 0;*/
/*  }*/
/*}*/

/*.welcome-image-container::after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: 40%;*/
/*  left: 50%;*/
/*  width: 350px;*/
/*  height: 350px;*/
/*  background: radial-gradient(circle, rgba(108, 217, 227,0.4) 5%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%);*/
/*  transform: translate(-50%, -50%);*/
/*  border-radius: 50%;*/
/*  opacity: 0;*/
/*  animation: lightBulb 10s infinite;*/
/*}*/

